import { gql } from '@apollo/client';
import { IGraphQLOperationList } from '../../../../infra/graphql/interfaces/operation.interface';

const names = ['coordenadoriasOrdensList', 'tipoOrdensList'];

const operation = gql`
  query {
    ${names[0]} {
      coordenadoria
    }
    ${names[1]} {
      tipoOrdem
    }
  }
`;

export const LIST_COORDENADORIAS_TIPO_ORDENS: IGraphQLOperationList = { names, operation };
