import { useQuery } from '@apollo/client';
import { utcToZonedTime } from 'date-fns-tz';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { clearLocalState, isValid, writeLocalState } from '../../../infra/apollo/cache';
import { GET_LOCAL_STATE } from '../../../infra/apollo/cache/queries/local-state.query';
import { useLazyQuery } from '../../../infra/apollo/hooks/query.hook';
import { UserState } from '../../../infra/apollo/types/user-state.type';
import { AuthStatus } from '../constants/enum/authStatus.enum';
import { DEFAULT_MENU, DEFAULT_USER } from '../constants/static/auth.static';
import { GET_AUTORIZAR } from '../queries/auth/get-autorizar.query';
import { GET_ME } from '../queries/auth/get-me.query';
import { Credenciais, MenuArvore, MenuDaArvore, User } from '../types/auth.types';

export type AuthContextData = {
  status: AuthStatus;
  user: User | null;
  menu: MenuArvore[];
  permissoes: string[];
  exp: Date | undefined;
  signOut(): void;
  setExpired(): void;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export type AuthProviderProps = {
  isSignIn: boolean;
  onLoginComplete: () => void;
  ticket: string | null;
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  isSignIn,
  ticket,
  children,
  onLoginComplete
}: AuthProviderProps) => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  const [status, setStatus] = useState<AuthStatus>(AuthStatus.Unauthorized);
  const [user, setUser] = useState<User>(DEFAULT_USER);
  const [menu, setMenu] = useState<MenuArvore[]>(DEFAULT_MENU);
  const [permissoes, setPermissoes] = useState<string[]>([]);
  const [exp, setExp] = useState<Date>();

  const { data: localState, refetch: refetchLocalState } = useQuery(GET_LOCAL_STATE);
  const { query: getMe, data: userState } = useLazyQuery<{ me: UserState }>(GET_ME);
  const { query: autenticar, data: credenciais } = useLazyQuery<{ autorizar: Credenciais }>(
    GET_AUTORIZAR,
    { variables: { ticket } }
  );

  useEffect(() => {
    console.log('Env: ', process.env.REACT_APP_ENV);
    if (possuiLocalState()) restaurarSessao(localState);
    else getMe();
  }, []);

  useEffect(() => {
    if (ticket) {
      if (isSignIn) autenticar();
    }
  }, [ticket]);

  useEffect(() => {
    if (!credenciais) return;
    if (!credenciais.autorizar.autenticado) {
      naoAutorizado();
      return;
    }

    autorizado(credenciais.autorizar);
    onLoginComplete();
  }, [credenciais]);

  useEffect(() => {
    if (!userState) return;

    console.log('UserState: ', userState);
    restaurarSessao(userState.me);
    writeLocalState(userState.me);
    refetchLocalState();
  }, [userState]);

  const autorizado = (credencial: Credenciais): void => {
    //const authUser = getAuthUser(usuario);

    console.log('expiration: ', credencial.expiration);
    console.log('authUser', credencial.usuario);
    setUser(credencial.usuario);
    setPermissoes(getPermissoes(credencial.arvoreMenu));
    setMenu(credencial.arvoreMenu);

    const localExp = utcToZonedTime(credencial.expiration, timezone);
    setExp(localExp);

    writeLocalState({
      user: credencial.usuario,
      menu: credencial.arvoreMenu,
      exp: localExp
    });
    refetchLocalState();

    setStatus(AuthStatus.Signed);
  };

  const naoAutorizado = (): void => {
    setStatus(AuthStatus.Unauthorized);
  };

  const restaurarSessao = (localState: UserState): void => {
    setUser(localState.user);
    setPermissoes(getPermissoes(localState.menu));
    setMenu(localState.menu);
    setExp(localState.exp);
    setStatus(AuthStatus.Signed);

    onLoginComplete();
  };

  const getPermissoes = (arvoreMenu: MenuArvore[]): string[] => {
    const modulo = arvoreMenu.flatMap((el: MenuArvore) => el.arvoreDeMenu ?? []);
    const nivel = modulo.flatMap((el: MenuDaArvore) => el.arvoreDeMenu ?? []);
    const rotas = nivel.flatMap((el: MenuDaArvore) => el.url ?? []);

    return rotas;
  };

  const possuiLocalState = (): boolean => {
    return status !== AuthStatus.Signed && isValid(localState);
  };

  const setExpired = (): void => {
    setStatus(AuthStatus.Expired);
  };

  const signOut = (): void => {
    clearLocalState();
    setStatus(AuthStatus.Logout);
  };

  return (
    <AuthContext.Provider value={{ status, user, menu, permissoes, exp, signOut, setExpired }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth PRECISA ser utilizado dentro de um AuthProvider');

  return context;
}
