import CloseIcon from '@mui/icons-material/Close';
import React, { createContext, useContext } from 'react';
import { SnackbarContextData, SnackbarInput, SnackbarProviderProps } from '../types/snackbar.types';
import styled from 'styled-components';
import { SnackbarKey, SnackbarProvider as SnackbarProviderStack } from 'notistack';
import { IconButton } from '@mui/material';

export const NotificationContext = createContext<SnackbarContextData>({} as SnackbarContextData);

const CloseIconStyled = styled(CloseIcon)`
  color: white;
`;

export function NotificationProvider({ children }: SnackbarProviderProps) {
  const notistackRef = React.createRef<SnackbarProviderStack>();
  const second = 1000;
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  function notify(snackInput: SnackbarInput) {
    notistackRef.current?.enqueueSnackbar(snackInput.message, {
      variant: snackInput.severity
    });
  }

  function notifyError(message: string) {
    notify({ message, severity: 'error' });
  }

  function notifyWarning(message: string) {
    notify({ message, severity: 'warning' });
  }

  function notifySuccess(message: string) {
    notify({ message, severity: 'success' });
  }

  return (
    <NotificationContext.Provider value={{ notify, notifyError, notifyWarning, notifySuccess }}>
      <SnackbarProviderStack
        ref={notistackRef}
        autoHideDuration={second * 3}
        preventDuplicate
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIconStyled />
          </IconButton>
        )}
      >
        {children}
      </SnackbarProviderStack>
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  return context;
}
